import React from 'react'
import { RootStateOrAny, useSelector } from 'react-redux'
import { Grid } from '@material-ui/core'
import { BaseNotificationLayoutStyles } from './BaseNotificationLayoutStyles'
import {useProgramContent} from '../../hooks/useProgramContent'
import { ClassNameMap } from '@material-ui/styles'

export default function BaseFooterLayout() {
  const styles: ClassNameMap = BaseNotificationLayoutStyles()
  const program: RootStateOrAny = useSelector<RootStateOrAny>(state => state.program?.program)
  
  const programContent: RootStateOrAny = useProgramContent();

  if(typeof program === 'undefined'){
    return (<div/>);
  }

  if(programContent?.appBar?.title === "Mazda Reimbursement Card"){
  return (
    <>
      <Grid container>
        <Grid container item direction={'column'} spacing={2} className={styles.bottomNotificationSection}>
          <Grid item>
          To activate or check the balance of your new Mazda Rewards Mastercard® issued through Onbe, please visit login.mypaymentvault.com/mazdausa or call 877-871-1267.
          </Grid>
          <Grid item>
          For program questions or if you have not received your new Mazda Rewards Mastercard®, please contact Program Headquarters at 888-867-8032 for further assistance.
          </Grid>
        </Grid>
      </Grid>
      </>
  )
  } else {
    return (<div/>);
  }
}
