import React from 'react'
import { Typography } from '@material-ui/core'
import { LandingDescriptionComponentStyles } from './LandingDescriptionComponentStyles'
import { ClassNameMap } from '@material-ui/styles'
import {useProgramContent} from '../../../hooks/useProgramContent'
import {RootStateOrAny} from 'react-redux'


export default function LandingDescriptionComponent() {
  const styles: ClassNameMap<"root"> = LandingDescriptionComponentStyles()
  const programContent: RootStateOrAny = useProgramContent();

  if (!programContent) return <Typography className={styles.root}>Please refresh your page and try again.</Typography>

if(programContent?.appBar?.title === "Mazda Reimbursement Card"){
  return (
    <>
    <Typography
          id={`pages-landing-description-0`}
          key={`pages-landing-description-0`}
          className={styles.root}
          align={'center'}
        >
          <b>Your previous Mazda Rewards Mastercard® has been frozen to reconcile charges, tips, and returns.  </b>
          </Typography>
          <Typography
          id={`pages-landing-description-1`}
          key={`pages-landing-description-1`}
          className={styles.root}
          align={'center'}
        >
          <b>Any remaining funds will be transferred from your old card to your new Mazda Rewards Debit Mastercard® by 9/9/24.  You will receive a follow-up email confirming that your funds have been transferred.</b>
        </Typography>
    </>
  )
  
} else {

  return (
    <>
      { programContent?.pages?.landing?.description.map((value: string, index: number) => (
        <Typography
          id={`pages-landing-description-${index}`}
          key={`pages-landing-description-${index}`}
          className={styles.root}
          align={'center'}
        >
          {value}
        </Typography>
      )) }
    </>
  )
}

}